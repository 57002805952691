import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-page" }
const _hoisted_2 = { class: "container" }

import { useSeoMeta } from '@unhead/vue'
import ContactLinks from '../About/ContactLinks.vue'
import { APP_NAME, APP_DESC } from '@/common/Constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

useSeoMeta({
    title: APP_NAME,
    description: APP_DESC,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_unref(APP_NAME)), 1 /* TEXT */),
      _createElementVNode("h2", null, _toDisplayString(_unref(APP_DESC)), 1 /* TEXT */),
      _createVNode(ContactLinks, { "is-dark": true })
    ])
  ]))
}
}

})