import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["href", "title"]

import { CONTACTS } from '@/common/Constants'
import { slugify } from '@/common/utils/slugify'


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactLinks',
  props: {
    isDark: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_SvgIcon = _resolveComponent("SvgIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
            contact: true,
            dark: _ctx.isDark,
        })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(CONTACTS), (contact) => {
      return (_openBlock(), _createElementBlock("div", {
        key: contact.label,
        class: "link"
      }, [
        _createVNode(_component_SvgIcon, {
          name: _unref(slugify)(contact.service),
          "is-dark": _ctx.isDark
        }, null, 8 /* PROPS */, ["name", "is-dark"]),
        _createElementVNode("a", {
          href: contact.url,
          title: contact.service,
          target: "_blank",
          rel: "noopener"
        }, _toDisplayString(contact.label), 9 /* TEXT, PROPS */, _hoisted_1)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}
}

})